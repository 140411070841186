import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Badge from '@mui/material/Badge';
import { mobile, tabletLandscape, tabletPortrait } from '../responsive';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { emptyReducer } from '../redux/userRedux';
import { Button, Menu, MenuItem } from '@mui/material';
import Fade from '@mui/material/Fade';

const Container = styled.div`
  // height: 60px;
  background-color: #c9eeff;
  ${mobile({})}
`;

const Wrapper = styled.div`
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${mobile({ padding: '10px 0px' })}
`;

const Left = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const Logo = styled.div`
  font-weight: bold;
  text-decoration: none;
  ${mobile({ marginLeft: '10px' })}
`;
const Right = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${(props) =>
    mobile({
      flex: 2,
      justifyContent: 'flex-start',
      flexDirection: 'column',
      alignItems: 'flex-start',
      position: 'fixed',
      top: props.activeTab === 'registerNow' ? 65 : 95,
      right: props.isOpened ? 0 : -230,
      width: '200px',
      height: '100vh',
      backgroundColor: '#afe6ff',
      boxShadow: '0 40px 30px rgba(0, 0, 0, 0.2)',
      padding: '20px 0px 0px 10px',
      zIndex: 3,
      transition: '0.4s ease-in-out',
    })};
  ${(props) =>
    tabletPortrait({
      flex: 2,
      justifyContent: 'flex-start',
      flexDirection: 'column',
      alignItems: 'flex-start',
      position: 'fixed',
      top: props.activeTab === 'registerNow' ? 65 : 95,
      right: props.isOpened ? 0 : -230,
      width: '200px',
      height: '100vh',
      backgroundColor: '#afe6ff',
      boxShadow: '0 40px 30px rgba(0, 0, 0, 0.2)',
      padding: '20px 0px 0px 10px',
      zIndex: 3,
      transition: '0.4s ease-in-out',
    })};
  ${(props) =>
    tabletLandscape({
      flex: 2,
      justifyContent: 'flex-start',
      flexDirection: 'column',
      alignItems: 'flex-start',
      position: 'fixed',
      top: props.activeTab === 'registerNow' ? 65 : 95,
      right: props.isOpened ? 0 : -230,
      width: '200px',
      height: '100vh',
      backgroundColor: '#afe6ff',
      boxShadow: '0 40px 30px rgba(0, 0, 0, 0.2)',
      padding: '20px 0px 0px 10px',
      zIndex: 3,
      transition: '0.4s ease-in-out',
    })};
`;
const NavMenuItem = styled.div`
  display: flex;
  font-size: 14px;
  cursor: pointer;
  margin-left: 25px;
  align-items: center;
  ${mobile({ fontSize: '12px', marginLeft: '5px', marginBottom: '10px' })}
  ${tabletPortrait({
    fontSize: '11px',
    marginLeft: '5px',
    marginBottom: '10px',
  })};
  ${tabletLandscape({
    fontSize: '11px',
    marginLeft: '5px',
    marginBottom: '10px',
  })};
`;

const Hamburger = styled.div`
  display: none;
  ${mobile({
    fontSize: '18px',
    marginRight: '25px',
    cursor: 'pointer',
    display: 'block',
  })}
  ${tabletPortrait({
    fontSize: '18px',
    marginRight: '25px',
    cursor: 'pointer',
    display: 'block',
  })}
  ${tabletLandscape({
    fontSize: '18px',
    marginRight: '25px',
    cursor: 'pointer',
    display: 'block',
  })}
`;

const NavItem = styled.span`
  border-bottom: ${(props) =>
    props.active === 'true' ? '1.6px #1a438e solid' : 'none'};
  &:hover {
    border-bottom: 1.6px #1a438e solid;
  }
`;

const Navbar = () => {
  const quantity = useSelector((state) => state.cart.quantity);
  const wishCount = useSelector((state) => state.wishlist.quantity);
  const [clicked, setClicked] = useState(false);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const activeTab = window.location.href.split('/')[3];
  const [anchorEl, setAnchorEl] = useState(null);
  const open = anchorEl;

  const handleProfileClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    dispatch(emptyReducer());
    localStorage.removeItem('key');
    navigate('/');
  };
  const compRef = useRef(null);

  const handleClick = () => {
    setClicked(!clicked);
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    document.addEventListener('scroll', handleClickOutside, true);
  }, []);

  const handleClickOutside = (e) => {
    if (compRef.current && !compRef.current.contains(e.target)) {
      setClicked(false);
    }
  };

  return (
    <Container ref={compRef}>
      <Wrapper>
        <Left>
          <Logo>
            <Link to='/' style={{ textDecoration: 'none', color: 'black' }}>
              <img
                src={require('../Assets/Logo/Impact.png')}
                height={45}
                alt=''
              />
            </Link>
          </Logo>
        </Left>
        <Right activeTab={activeTab} isOpened={clicked}>
          <NavMenuItem>
            <NavLink
              style={{ textDecoration: 'none', color: 'black' }}
              to={'/'}
            >
              <NavItem active={activeTab === '' ? 'true' : 'false'}>
                HOME
              </NavItem>
            </NavLink>
          </NavMenuItem>
          <NavMenuItem>
            <NavLink
              style={{ textDecoration: 'none', color: 'black' }}
              to={'/about'}
            >
              <NavItem active={activeTab === 'about' ? 'true' : 'false'}>
                ABOUT US
              </NavItem>
            </NavLink>
          </NavMenuItem>
          <NavMenuItem>
            <NavLink
              style={{ textDecoration: 'none', color: 'black' }}
              to={'/training'}
            >
              <NavItem active={activeTab === 'training' ? 'true' : 'false'}>
                TRAINING & PROJECTS
              </NavItem>
            </NavLink>
          </NavMenuItem>
          <NavMenuItem>
            <NavLink
              style={{ textDecoration: 'none', color: 'black' }}
              to={'/automation'}
            >
              <NavItem active={activeTab === 'automation' ? 'true' : 'false'}>
                AUTOMATION/SOLUTION
              </NavItem>
            </NavLink>
          </NavMenuItem>
          <NavMenuItem>
            <NavLink
              style={{ textDecoration: 'none', color: 'black' }}
              to={'/products'}
            >
              <NavItem active={activeTab === 'products' ? 'true' : 'false'}>
                E-STORE
              </NavItem>
            </NavLink>
          </NavMenuItem>

          {!user.isLoggedIn && (
            <NavMenuItem>
              <NavLink
                style={{ textDecoration: 'none', color: 'black' }}
                to={'/register'}
              >
                <NavItem>REGISTER</NavItem>
              </NavLink>
            </NavMenuItem>
          )}
          {!user.isLoggedIn && (
            <NavMenuItem>
              <NavLink
                style={{ textDecoration: 'none', color: 'black' }}
                to={'/login'}
              >
                <NavItem>SIGN IN</NavItem>
              </NavLink>
            </NavMenuItem>
          )}
          {user.isLoggedIn && (
            <NavMenuItem>
              <Link to={'/cart'}>
                <Badge
                  badgeContent={user.isLoggedIn ? quantity : 0}
                  color='primary'
                >
                  <ShoppingCartOutlinedIcon
                    style={{
                      marginLeft: '0px',
                      textDecoration: 'none',
                      color: 'black',
                    }}
                  />
                </Badge>
              </Link>
              <Button
                id='profile-button'
                aria-controls={open ? 'profile-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
                onClick={handleProfileClick}
              >
                <AccountCircleIcon
                  style={{
                    marginLeft: '15px',
                    color: '#378fa1',
                    fontSize: '28px',
                    textDecoration: 'none',
                    color: 'black',
                  }}
                />
              </Button>
              <Menu
                id='profile-menu'
                aria-labelledby='profile-button'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&::before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                <MenuItem onClick={() => { handleClose(); navigate('/myOrders')}}>My Orders</MenuItem>
                <MenuItem onClick={() => { handleClose(); logout();}}>Logout</MenuItem>
              </Menu>
            </NavMenuItem>
          )}
        </Right>
        <Hamburger>
          <i
            className={clicked ? 'fas fa-times' : 'fas fa-bars'}
            onClick={handleClick}
          ></i>
        </Hamburger>
      </Wrapper>
    </Container>
  );
};

export default Navbar;

import { createSlice } from '@reduxjs/toolkit'

const registrationSlice = createSlice({
    name: 'registration',
    initialState: {
        loading: false,
        error: false,
        message: "",
        data: []
    },
    reducers: {
        registrationStart: (state, action) => {
            state.loading = true;
        },
        registrationSuccess: (state, action) => {
            state.message = "success";
            state.error = false;
            state.loading = false;
            state.data = action.payload;
        },
        registrationFailure: (state, action) => {
            state.message = action.payload;
            state.error = true;
            state.loading = false;
            state.data = [];
        },
        resetReducer: (state) => {
            state.error = false;
            state.loading = false;
            state.message = "";
            state.data = [];
        },
    }
})

export const { registrationStart, registrationSuccess, resetReducer, registrationFailure } = registrationSlice.actions;
export default registrationSlice.reducer;
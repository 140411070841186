import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { mobile } from '../responsive'

const Container = styled.div`
    height: 30px;
    background-color: #05648e;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    ${mobile({ fontSize: '13px' })}
`

const Announcements = () => {
  return (
    <Container><Link style={{ color: 'white'}} to={'/registerNow'}>Register Now!</Link> &nbsp; Get a chance to win a free Component Kit!</Container>
  )
}

export default Announcements
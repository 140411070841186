import { createSlice } from "@reduxjs/toolkit";

const pincodeSlice = createSlice({
    name: 'pincode',
    initialState: {
        message: '',
        data: '',
        loading: false
    },
    reducers: {
        getPincodeDataStart: (state, action) => {
            state.loading = true;
        },
        getPincodeDataSuccess: (state, action) => {
            state.message = 'Success';
            state.loading = false;
            state.data = action.payload;
        },
        getPincodeDataFailure: (state, action) => {
            state.message = 'Error';
            state.loading = false;
        },
        resetPincodeData: (state, action) => {
            state.data = [];
            state.message = '';
            state.loading = '';
        }
    }
})

export const { getPincodeDataStart, getPincodeDataSuccess, getPincodeDataFailure, resetPincodeData } = pincodeSlice.actions;
export default pincodeSlice.reducer;
import { createSlice } from '@reduxjs/toolkit';

const orderRedux = createSlice({
  name: 'cart',
  initialState: {
    isLoading: false,
    status: false,
    message: '',
    ordersArray: [],
  },
  reducers: {
    orderCreationInitiated: (state, action) => {
      state.isLoading = true;
      state.message = '';
      state.status = false;
      state.ordersArray = [];
    },
    orderCreationSuccess: (state, action) => {
      state.isLoading = false;
      state.message = 'Success';
      state.status = true;
      state.ordersArray = [];
    },
    orderCreationFailed: (state, action) => {
      state.isLoading = false;
      state.message = 'Failed';
      state.status = false;
      state.ordersArray = [];
    },
    resetOrderCreation: (state, action) => {
      state.isLoading = false;
      state.message = '';
      state.status = false;
      state.ordersArray = [];
    },
    getAllOrdersSuccess: (state, action) => {
      state.isLoading = false;
      state.message = '';
      state.status = false;
      state.ordersArray = action.payload;
    },
    getAllOrdersStarted: (state, action) => {
      state.isLoading = false;
      state.message = '';
      state.status = false;
      state.ordersArray = [];
    },
    getAllOrdersFailed: (state, action) => {
      state.isLoading = false;
      state.message = '';
      state.status = false;
      state.ordersArray = [];
    },
  },
});

export const {
  orderCreationFailed,
  orderCreationInitiated,
  orderCreationSuccess,
  resetOrderCreation,
  getAllOrdersFailed,
  getAllOrdersStarted,
  getAllOrdersSuccess,
} = orderRedux.actions;
export default orderRedux.reducer;

import { userRequest } from "../../RequestMethods";
import {
    deleteUserDelLocFailed,
    deleteUserDelLocSuccess,
  getuserDelLocFailed,
  getuserDelLocSuccess,
  saveDeliveryFailed,
  saveDeliverySuccess,
  startGetDeliveryData,
  startSaveDelivery,
} from "../CheckoutRedux";

export const saveDelInfo = async (dispatch, data) => {
  dispatch(startSaveDelivery());
  await userRequest
    .post("/delivery/saveInfo", data)
    .then((resp) => {
      dispatch(saveDeliverySuccess(resp.data));
    })
    .catch((err) => {
      dispatch(saveDeliveryFailed());
    });
};

export const getUserDeliveryLocations = async (dispatch) => {
  dispatch(startGetDeliveryData());
  await userRequest
    .get("/delivery/getDeliveryLocations")
    .then((resp) => {
      dispatch(getuserDelLocSuccess(resp.data));
    })
    .catch((err) => {
      dispatch(getuserDelLocFailed());
    });
};

export const editUserDeliveryLocations = async (dispatch, data, id) => {
  dispatch(startSaveDelivery());
  await userRequest
    .patch("/delivery/editDeliveryLocation/" + id, data)
    .then((resp) => {
      dispatch(saveDeliverySuccess(resp.data));
    })
    .catch((err) => {
      dispatch(saveDeliveryFailed());
    });
};

export const deleteUserDeliveryLocations = async (dispatch, id) => {
  dispatch(startGetDeliveryData());
  await userRequest
    .delete("/delivery/deleteDeliveryLocation/" + id)
    .then((resp) => {
      dispatch(deleteUserDelLocSuccess(resp.data));
    })
    .catch((err) => {
      dispatch(deleteUserDelLocFailed());
    });
};

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import Product from "./Product";
import { getAllWishlistItems } from "../redux/APIs/WishlistAPIs";
import { getAllProd } from "../redux/APIs/ProductAPIs";

const Container = styled.div`
  padding: 10px 22px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Products = ({ cat, filters, sort }) => {
  const [products, setProducts] = useState([]);
  const user = useSelector(state => state.user);
  const dispatch = useDispatch();
  const productList = useSelector((state) => state.productList.productList);

  useEffect(() => {
    if (cat && filters) {
      getAllProd(dispatch, filters, sort, cat);
    } else if (filters) {
      getAllProd(dispatch, filters, sort, "");
    } else {
      getAllProd(dispatch, "", "", "");
      user.isLoggedIn && getAllWishlistItems(dispatch, user.currentUser.user.username);
    }
    // eslint-disable-next-line
  }, [cat, filters, sort]);

  useEffect(() => {
    if (typeof productList !== "undefined") {
      setProducts(productList);
    }
    // eslint-disable-next-line
  }, [productList]);

  // useEffect(() => {
  //   if (typeof cat !== "undefined") {
  //     let array = [...productList];
  //     if (sort === "newest") {
  //       setFilteredProducts(array.sort((a, b) => a.updatedAt - b.updatedAt));
  //     }
  //     else if(sort === "asc") {
  //       setFilteredProducts(array.sort((a,b) => a.price - b.price));
  //     }
  //     else {
  //       setFilteredProducts(array.sort((a,b) => b.price - a.price));
  //     }
  //   }
  //   // eslint-disable-next-line
  // }, [productList]);

  return (
    <Container>
      {filters
        ? products.map((product, i) => (
            <Product product={product} key={product.id} index={i} />
          ))
        : 
        products
            ?.slice(0, 8)
            .map((product, i) => <Product product={product} key={product.id} index={i} />)
            }
    </Container>
  );
};

export default Products;
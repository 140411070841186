import { combineReducers, configureStore } from "@reduxjs/toolkit";
import cartReducer from "./cartRedux";
import productsReducer from "./productsRedux";
import productReducer from "./productRedux";
import userReducer from "./userRedux";
import wishlistReducer from "./wishlistRedux";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import stripeReducer from "./stripeRedux";
import registrationRedux from "./registrationRedux";
import pinCodeRedux from "./pinCodeRedux";
import CheckoutRedux from "./CheckoutRedux";
import orderRedux from "./orderRedux";

const persistConfig = {
  key: "root",
  version: 1,
  storage
};

const rootReducer = combineReducers({ 
    cart: cartReducer,
    productList: productsReducer,
    product: productReducer,
    user: userReducer,
    wishlist: wishlistReducer,
    stripe: stripeReducer,
    registration: registrationRedux,
    pincode: pinCodeRedux,
    checkout: CheckoutRedux,
    order: orderRedux
})
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    }
  })
});

export let persistor = persistStore(store);
import React, { useEffect } from "react";
import styled from "styled-components";
import { mobile } from "../responsive";
import { Navigate, useNavigate } from "react-router-dom";
import { emptyReducer } from "../redux/userRedux";
import { useDispatch } from "react-redux";
import Loader from '../Assets/Loader3.gif'
import { LinearProgress } from "@mui/material";
import { resetPaymentReducer } from "../redux/stripeRedux";

const LoaderContainer = styled.div`
  margin-top: 30px;
`

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #55caca4f;
`;

const Wrapper = styled.div`
  padding: 20px;
  background-color: white;
  display: flex;
  justify-content: center;
  ${mobile({ width: "75%" })}
`;

const SessionTimeout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(emptyReducer());
    dispatch(resetPaymentReducer());
  }, []);

  setTimeout(() => {
    navigate('/')
  }, 3000);

  const [progress, setProgress] = React.useState(0);
  const [buffer, setBuffer] = React.useState(10);

  const progressRef = React.useRef(() => {});
  React.useEffect(() => {
    progressRef.current = () => {
      if (progress > 100) {
        setProgress(0);
        setBuffer(10);
      } else {
        const diff = Math.random() * 10;
        const diff2 = Math.random() * 10;
        setProgress(progress + diff);
        setBuffer(progress + diff + diff2);
      }
    };
  });

  React.useEffect(() => {
    const timer = setInterval(() => {
      progressRef.current();
    }, 200);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Container>
      <Wrapper>
        <h3>
          401 | Your session has been Timed Out. Redirecting, please wait.. 
          <LoaderContainer><LinearProgress variant="buffer" value={progress} valueBuffer={buffer} /></LoaderContainer>
        </h3>
      </Wrapper>
    </Container>
  );
};

export default SessionTimeout;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import Announcements from "../components/Announcements";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import NewsLetter from "../components/NewsLetter";
import { emptyCartReducer } from "../redux/cartRedux";
import { resetPaymentReducer } from "../redux/stripeRedux";
import { verifyPaymentByPhonePe } from "../redux/APIs/PaymentAPIs";
import { resetOrderCreation } from "../redux/orderRedux";

const Message = styled.div`
  display: flex;
  justify-content: center;
  font-size: 26px;
  font-weight: 500;
  margin-top: 6rem;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 40px;
`;

const Button = styled.div`
  font-wight: 600;
  padding: 15px;
  border: none;
  background-color: teal;
  color: white;
  cursor: pointer;
  margin-bottom: 6rem;
`;

const Thankyou = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const cartItems = useSelector(state => state.cart)
    const user = useSelector((state) => state.user);

    useEffect(() => {
      if(location.state?.gateway === 'RazorPay') {
        console.log('Ok');
      }else {
        verifyPaymentByPhonePe(dispatch, cartItems.products)
      }
      dispatch(emptyCartReducer());
      dispatch(resetOrderCreation());
      // eslint-disable-next-line
    }, [])
    
    return (
    <div>
      <Announcements />{console.log(cartItems, user)}
      <Navbar />
      <Message>Thankyou for Shopping with us.</Message>
      <Wrapper>
        <Link to={"/"} style={{ textDecoration: "none", color: "white" }}>
          <Button>CONTINUE EXPLORING</Button>
        </Link>
      </Wrapper>
      <NewsLetter />
      <Footer />
    </div>
  );
};

export default Thankyou;

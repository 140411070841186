import axios from "axios"
import { getPincodeDataFailure, getPincodeDataStart, getPincodeDataSuccess } from "../pinCodeRedux"

export const getPinCodeData = async (dispatch, pin) => {
    dispatch(getPincodeDataStart());
    axios.get('https://api.postalpincode.in/pincode/' + pin).then((resp) => {
        if(resp.data[0].Status === 'Success') {
            dispatch(getPincodeDataSuccess(resp.data[0].PostOffice))
            console.log('Postal', resp.data[0].PostOffice)
        }
        else if(resp.data[0].Status === 'Error'){
            dispatch(getPincodeDataFailure())
            console.log(resp.data[0])
        }
    })
    .catch(err => console.log(err))
}
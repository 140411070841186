import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { login } from "../redux/APIs/UserAPIs";
import { mobile, tabletLandscape, tabletPortrait } from "../responsive";
import { useForm } from "react-hook-form";
import { emptyReducer } from "../redux/userRedux";
import { resetReducer } from "../redux/registrationRedux";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #55caca4f;
`;
const Wrapper = styled.div`
  padding: 20px;
  width: 35%;
  background-color: white;
  ${mobile({ width: '75%'})}
  ${tabletPortrait({ width: '50%'})}
  ${tabletLandscape({ width: '35%'})}
`;
const Title = styled.h1`
  font-size: 24px;
  font-weight: 300;
`;
const Form = styled.div`
  display: flex;
  flex-direction: column;
`;
const Input = styled.input`
  flex: 1;
  min-width: 40%;
  margin: 10px 0px;
  padding: 10px;
  border: ${(props) => (props.isValid ? "1px red solid" : "1px solid black")};
  border-radius: 2px;
  &:focus {
    outline: none;
  }
`;
const Link = styled.span`
  margin: 5px 0px;
  font-size: 12px;
  text-decoration: underline;
`;
const Button = styled.button`
  width: 35%;
  border: none;
  padding: 12px 13px;
  background-color: teal;
  margin-bottom: 10px;
  color: white;
  cursor: pointer;
  &:disabled {
    color: white;
    cursor: not-allowed;
    background-color: #55caca4f;
  }
  ${mobile({ width: '35%', padding: '8px 10px' })}

`;

const Error = styled.span`
  color: red;
`;

const Login = () => {
  const { isFetching, error, isLoggedIn, currentUser, message } = useSelector(
    (state) => state.user
  );
  const [successMsg, setSuccessMsg] = useState('')
  const [errorMsg, setErrorMsg] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const location = useLocation();

  const onSubmit = (data) => {
    login(dispatch, data);
  };
  const redirect = () => {
    if(location.state && location.state.url) {
      window.location.href = location.state.url
    }
    else {
      window.location.href = '/'
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      currentUser !== null &&
      localStorage.setItem("key", currentUser.accessToken);
      redirect();
    }
    // eslint-disable-next-line
  }, [isLoggedIn]);

  useEffect(() => {
    if(location.state && location.state.newUser === true) {
      setSuccessMsg('User Created Successfully!');
    }
    if (!isLoggedIn) {
      dispatch(emptyReducer());
    }
    dispatch(resetReducer());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (error) setErrorMsg(message);
    // eslint-disable-next-line
  }, [error]);

  return (
    <Container>
      <Wrapper>
        <Title>SIGN IN</Title>
        <form onSubmit={handleSubmit((data) => onSubmit(data))}>
          <Form>
            <p style={{ color: 'green', margin: '0px' }}>{successMsg}</p>
            <Input {...register("email", { required: true })} isValid={errors?.username ? true : false} type={"text"} placeholder="Email Address" />
            <Input {...register("password", { required: true, minLength: 5 })} isValid={errors?.password ? true : false} type={"password"} placeholder="Password" />
            <Button type="submit" disabled={isFetching}>LOG IN</Button>
            {error && <Error>{errorMsg}</Error>}
            <Link>
              <NavLink style={{ textDecoration: "none", color: "black" }} to={"/forgotPassword"}>
                FORGOT YOUR PASSWORD?
              </NavLink>
            </Link>
            <Link>
              <NavLink style={{ textDecoration: "none", color: "black" }} to={"/register"}>
                CREATE A NEW ACCOUNT
              </NavLink>
            </Link>
          </Form>
        </form>
      </Wrapper>
    </Container>
  );
};

export default Login;

import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import Announcements from "../components/Announcements";
import styled from "styled-components";
import { mobile } from "../responsive";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";

const Container = styled.div``;

const Title = styled.h1`
  margin: 20px;
  ${mobile({ fontSize: "24px" })}
`;

const Wrapper = styled.div`
  margin: 20px;
  padding: 0px 20px;
`;

const Desc = styled.p`
  ${mobile({ fontSize: "14px" })}
`;

const OrderedListItem = styled.li`
  margin-top: 10px;
  ${mobile({ fontSize: "14px" })}
`;

const BoldText = styled.p`
  font-weight: 600;
  text-decoration: underline;
  ${mobile({ fontSize: "14px" })}
`;

const ContactInfo = styled.p`
  font-weight: 500;
  margin: 0px;
  ${mobile({ fontSize: "14px" })}
`;

const TermsCondition = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <Announcements />
      <Navbar />
      <Title>Terms and Conditions</Title>
      <Wrapper>
        <BoldText>Effective Date: 10-05-2023</BoldText>
        <Desc>
          Please read these Terms and Conditions ("Terms") carefully before
          using our website. These Terms govern your access to and use of IMPACT
          TECHNOLOGIES ("Website"), including any purchases made through the
          Website. By accessing or using the Website, you agree to be bound by
          these Terms. If you do not agree with any part of these Terms, you
          should not use the Website.
        </Desc>
        <ol>
          <OrderedListItem>
            <BoldText>Use of the Website</BoldText>
            <Desc>
              We may collect certain personal information from you when you
              visit our website, register an account, place an order, or
              interact with our services. The types of information we may
              collect include:
            </Desc>
            <ul>
              <li>
                <b>Eligibility:</b> You must be at least 10 years old to use the
                Website. By using the Website, you represent and warrant that
                you are of legal age to form a binding contract.
              </li>
              <li>
                <b>Account Registration:</b> To access certain features of the
                Website, you may be required to register for an account. You are
                responsible for maintaining the confidentiality of your account
                credentials and for all activities that occur under your
                account. You agree to provide accurate, current, and complete
                information during the registration process.
              </li>
              <li>
                <b>Prohibited Activities:</b> You agree not to engage in any of
                the following activities:
                <ol type="a">
                  <li>
                    Violating any applicable laws, regulations, or third-party
                    rights.
                  </li>
                  <li>
                    Interfering with or disrupting the Website's functionality
                    or servers.
                  </li>
                  <li>
                    Using any automated means or software to access or scrape
                    the Website without our prior written consent.
                  </li>
                  <li>
                    Posting or transmitting any harmful or malicious content,
                    including viruses, malware, or any material that is
                    unlawful, offensive, or infringing.
                  </li>
                  <li>
                    Misrepresenting your identity or impersonating any person or
                    entity.
                  </li>
                  <li>
                    Engaging in fraudulent activities, including payment fraud
                    or unauthorized use of payment information.
                  </li>
                </ol>
              </li>
            </ul>
          </OrderedListItem>
          <OrderedListItem>
            <BoldText>Product Information and Pricing</BoldText>
            <ul>
              <li>
                <b>Product Descriptions:</b> We strive to provide accurate and
                detailed product descriptions on the Website. However, we do not
                guarantee that the information is complete, current, or
                error-free. We reserve the right to correct any errors,
                inaccuracies, or omissions and to change or update product
                information at any time without prior notice.
              </li>
              <li>
                <b>Pricing:</b> Product prices listed on the Website are in
                Indian Rupees (Rs.) and are subject to change without notice. We
                make efforts to ensure accurate pricing, but in the event of a
                pricing error, we reserve the right to cancel or refuse any
                orders placed for the incorrectly priced product.
              </li>
            </ul>
          </OrderedListItem>
          <OrderedListItem>
            <BoldText>Orders and Payments</BoldText>
            <ul>
              <li>
                <b>Order Placement:</b> When you place an order through the
                Website, it constitutes an offer to purchase the selected
                products or services. We reserve the right to accept or decline
                your order in our sole discretion.
              </li>
              <li>
                <b>Payment:</b> Payment for orders placed through the Website
                must be made using the available payment methods specified
                during the checkout process. By providing your payment
                information, you represent and warrant that you are authorized
                to use the chosen payment method.
              </li>
              <li>
                <b>Order Confirmation:</b> After you place an order, you will
                receive an order confirmation via email. This confirmation does
                not signify acceptance of your order; it is only an
                acknowledgment that we have received it.
              </li>
              <li>
                <b>Shipping and Delivery:</b> We will make reasonable efforts to
                fulfill and deliver your order in a timely manner. Delivery
                times may vary depending on your location and other factors
                beyond our control. Any specified delivery dates are estimates
                and your order can take 1 - 8 business days (1 Min and 8 Max,
                this timeline does not include any holiday).
              </li>
            </ul>
          </OrderedListItem>
          <OrderedListItem>
            <BoldText>Returns and Refunds</BoldText>
            <ul>
              <li>
                <b>Return Policy:</b> We offer a 7-days return policy for
                eligible products. Please refer to our separate{" "}
                <Link to={"/refundPolicy"} style={{ color: "black" }}>
                  <b>Return Policy</b>
                </Link>
                for detailed information on the return process, eligibility
                criteria, and any associated fees or restrictions.
              </li>
              <li>
                <b>Refunds:</b> If you are eligible for a refund, we will
                process it within a reasonable timeframe using the original
                payment method. Please note that certain fees or charges may be
                deducted from the refund amount, as outlined in our Return
                Policy.
              </li>
            </ul>
          </OrderedListItem>
          <OrderedListItem>
            <BoldText>Intellectual Property</BoldText>
            <ul>
              <li>
                <b>Ownership:</b> The Website and its entire contents, including
                text, graphics, logos, images, and software, are owned by or
                licensed to us and are protected by intellectual property laws.
                You agree not to reproduce, distribute, modify, or create
                derivative works from any part of the Website without our prior
                written consent.
              </li>
            </ul>
          </OrderedListItem>
          <OrderedListItem>
            <BoldText>Limitation of Liability</BoldText>
            <ul>
              <li>
                <b>Disclaimer of Warranties:</b> The Website is provided on an
                "as is" and "as available" basis, without any warranties or
                representations of any kind, whether express or implied. We do
                not warrant that the Website will be error-free, uninterrupted,
                or secure.
              </li>
              <li>
                <b>Limitation of Liability:</b> To the maximum extent permitted
                by applicable law, we shall not be liable for any indirect,
                incidental, consequential, or punitive damages arising out of or
                relating to your use of the Website or any products purchased
                through the Website.
              </li>
            </ul>
          </OrderedListItem>
          <OrderedListItem>
            <BoldText>Indemnification</BoldText>
            <Desc>
              You agree to indemnify and hold us harmless from any claims,
              damages, liabilities, and expenses (including reasonable
              attorneys' fees) arising out of or related to your use of the
              Website, violation of these Terms, or infringement of any rights
              of third parties.
            </Desc>
          </OrderedListItem>
          <OrderedListItem>
            <BoldText>Modifications and Termination</BoldText>
            <Desc>
              We reserve the right to modify, suspend, or terminate the Website
              or any part thereof at any time without prior notice. We may also
              update these Terms from time to time. Your continued use of the
              Website after any modifications to the Terms constitutes your
              acceptance of the revised terms.
            </Desc>
          </OrderedListItem>
          <OrderedListItem>
            <BoldText>Governing Law and Dispute Resolution</BoldText>
            <Desc>
              These Terms shall be governed by and construed in accordance with
              the laws of UP. Any disputes arising out of or relating to these
              Terms or your use of the Website shall be subject to the exclusive
              jurisdiction of the courts located in UP.
            </Desc>
          </OrderedListItem>
          <OrderedListItem>
            <BoldText>Severability</BoldText>
            <Desc>
              If any provision of these Terms is found to be invalid, illegal,
              or unenforceable, the remaining provisions shall continue in full
              force and effect.
            </Desc>
          </OrderedListItem>
          <OrderedListItem>
            <BoldText>Entire Agreement</BoldText>
            <Desc>
              These Terms constitute the entire agreement between you and IMPACT
              TECHNOLOGIES regarding your use of the Website and supersede any
              prior agreements or understandings, whether written or oral.
            </Desc>
            <Desc>
              If you have any questions or concerns regarding these Terms,
              please contact us using the information provided on the Website.
            </Desc>
            <Desc>
              By using the Website, you acknowledge that you have read,
              understood, and agree to be bound by these Terms and Conditions.
            </Desc>
          </OrderedListItem>
        </ol>
      </Wrapper>
      <Footer />
    </Container>
  );
};

export default TermsCondition;

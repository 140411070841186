import React, { useEffect } from 'react';
import styled from 'styled-components';
import Announcements from '../components/Announcements';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import { mobile } from '../responsive';
import { useDispatch, useSelector } from 'react-redux';
import loader from '../Assets/Loader2.gif';
import { getMyOrdersData } from '../redux/APIs/MyOrdersAPIs';

const Container = styled.div``;
const Wrapper = styled.div`
  padding: 20px;
  ${mobile({ padding: '10px' })}
`;
const Title = styled.h1`
  font-weight: 300;
  text-align: center;
`;
const Bottom = styled.div`
  display: flex;
  padding: 40px 0px;
  ${mobile({ flexDirection: 'column' })}
`;
const Info = styled.div`
  flex: 3;
`;
const Product = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0px;
  ${mobile({ flexDirection: 'column' })}
`;
const ProductDetails = styled.div`
  flex: 2;
  display: flex;
`;
const ProductName = styled.span``;
const ProductId = styled.span``;
const Image = styled.img`
  width: 200px;
`;
const Details = styled.div`
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;
const PriceDetails = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  ${mobile({
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '10px 20px',
  })}
`;
const Hr = styled.hr`
  background-color: #eee;
  height: 1px;
  border: 1px solid;
`;
const Message = styled.div`
  display: flex;
  justify-content: center;
  font-weight: 500;
  margin-top: 6rem;
  margin-bottom: 6rem;
`;
const Loader = styled.img`
  object-fit: cover;
  width: 30px;
  ${mobile({ height: '50vh' })}
`;

const MyOrders = () => {
  const orders = useSelector((state) => state.order);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    user.isLoggedIn && getMyOrdersData(dispatch);
    // eslint-disable-next-line
  }, []);

  const renderItems = () => {
    if (user.isLoggedIn) {
      if (orders.ordersArray.length === 0) {
        return <Message>All your orders will appear here!</Message>;
      } else {
        return (
          <Bottom>
            <Info>
              {orders.ordersArray.map((product, i) => {
                let imgUrl = `${
                  process.env.REACT_APP_BACKEND_URL
                }/${product.img_path.replace('\\', '/')}`;
                return (
                  <div key={i}>
                    <Product>
                      <ProductDetails>
                        <Image src={imgUrl} />
                        <Details>
                          <ProductName>
                            <b>Product: </b> {product.title}
                          </ProductName>
                          <ProductId>
                            <b>Description: </b>{' '}
                            {product.description &&
                            product.description.length > 100
                              ? product.description.slice(0, 100) + '...'
                              : product.description}
                          </ProductId>
                          <ProductName>
                            <b>Price: </b> {product.amount}
                          </ProductName>
                          <ProductName>
                            <b>Quantity: </b> {product.quantity}
                          </ProductName>
                        </Details>
                      </ProductDetails>
                      {!orders.isLoading ? (
                        <ProductDetails>
                          <Details>
                            <ProductName>
                              <b>Order ID: </b> {product.order_id}
                            </ProductName>
                            <ProductName>
                              <b>Transaction ID: </b> {product.transaction_id}
                            </ProductName>
                            <ProductName>
                              <b>Delivery Status: </b> {product.status}
                            </ProductName>
                          </Details>
                        </ProductDetails>
                      ) : (
                        <PriceDetails>
                          <Loader src={loader} />
                        </PriceDetails>
                      )}
                    </Product>
                    {i !== orders.ordersArray.length - 1 && <Hr />}
                  </div>
                );
              })}
            </Info>
          </Bottom>
        )
      }
    } else {
      return <Message>Please login to see your orders.</Message>;
    }
  };

  return (
    <Container>
      <Announcements />
      <Navbar />
      <Wrapper>
        <Title>YOUR ORDERS</Title>
        {renderItems()}
      </Wrapper>
      <Footer />
    </Container>
  );
};

export default MyOrders;

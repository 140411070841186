import React, { useEffect } from "react";
import Announcements from "../components/Announcements";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import styled from "styled-components";
import { mobile } from "../responsive";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

const Container = styled.div``;

const Title = styled.h1`
  margin: 20px;
`;

const MiddleDesc = styled.div`
  flex: 1;
  margin: 5px;
`;

const Middle = styled.div`
  display: flex;
  flex-direction: column;
`;

const MiddleDescTitle = styled.div`
  text-align: center;
  font-size: 20px;
  text-decoration: underline;
  font-weight: bold;
  color: #1155cc;
  align-items: center;
`;

const Wrapper = styled.div`
  margin: 30px;
  padding: 0px 20px;
`;

const Desc = styled.p``;


const MiddleContentContainerOut = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  ${mobile({ flexFlow: 'row wrap' })}

`;

const MiddleContentContainer = styled.div`
`;

const MiddleContentTitleContainer = styled.div`
  font-weight: 550;
`;

const MiddleImageContainerOut = styled.div`
  display: flex;
  justify-content: center;
`;

const MiddleImageContainer = styled.div`
`;


const OutContainerSlider = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 2.5rem;
`;

const AutoSoln = () => {
  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 4, itemsFit: 'contain' }
  };

  const items = [
    <div style={{ height: '300', width: '500'}}><img src={require('../Assets/Logo/sup.jpg')} height={200} width={300} alt="" /></div>,
    <div style={{ height: '300', width: '500'}}><img src={require('../Assets/Logo/gama.jpg')} height={200} width={300} alt="" /></div>,
    <div style={{ height: '300', width: '500', marginTop: '30px'}}><img src={require('../Assets/Logo/client4.JPG')} style={{ marginLeft: '40px'}} height={150} width={200} alt="" /></div>,
    <div style={{ height: '300', width: '500'}}><img src={require('../Assets/Logo/sp.jpg')} height={200} width={200} alt="" /></div>,
    <div style={{ height: '200', width: '500', marginTop: '40px'}}><img src={require('../Assets/Logo/gy1.jpg')} height={150} width={300} alt="" /></div>,
    <div style={{ height: '300', width: '500'}}><img src={require('../Assets/Logo/lnt1.jpg')} height={190} width={300} alt="" /></div>,
    <div style={{ height: '300', width: '500'}}><img src={require('../Assets/Logo/Afcons.jfif')} height={190} width={300} alt="" /></div>,
  ];

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Container>
      <Announcements />
      <Navbar />
      <Title>Automation & Solution</Title>
      <Wrapper>
        <Desc>
          Impact Technologies is a complete business partner and leading
          provider of products, applications &. solutions for Entrance, Secured
          Entrance and Hygiene Automation; catering to Industrial, Commercial,
          Institutional, Pharmaceutical, Hospitality, Public&. Private Sector
          needs.
          <br />
          Our overarching product portfolio is delivered PAN India through a
          strong network of distributors &. Installers to reach out to the end
          customers. Impact Technologies means hi-tech facilities, meticulous
          high quality delivery systems, service - oriented approach and very
          competitive prices. Impact Technologies offers comprehensive products,
          applications and solutions that attract and retain architects,
          designers, specifiers, builders and entrepreneurs.
          <br />
          Our clients located across the nation, feel at home working with
          Impact Technologies. With full national presence and a comprehensive
          product portfolio -comprising products from leading principals and
          advanced experts in our chosen markets, drives TOSHI to deliver
          extraordinary solutions that help our clients save money & enhance
          productivity.
        </Desc>
        <Middle>
          <MiddleDesc>
            <MiddleDescTitle>
              Benefits of 'Impact Technologies’ Robotics Process Automation Solutions
            </MiddleDescTitle>
            <Desc>
            Impact Technologies has proven its comprehensive expertise and experience in robotic process automation services, helping you build your business beyond conventional automation. Our RPA solution covers each and every significant facet of optical authentication exception handling and analysis, data processing and storage.
            </Desc>
            <MiddleContentContainerOut>
            <MiddleContentContainer>
              <MiddleContentTitleContainer>
                <ul style={{ listStyleType: 'square' , fontWeight: 'normal', lineHeight: 1.8, marginTop: '20px'}}>
                  <li>The essentials of coding: Logic, Structure, Sequence</li>
                  <li>To create Websites, Animations & Applications.</li>
                  <li>To manipulate data using programming languages.</li>
                  <li>How to be a problem solver</li>
                  <li>How technologies converse with each other</li>
                </ul>
              </MiddleContentTitleContainer>
            </MiddleContentContainer>
            <MiddleImageContainerOut>
              <MiddleImageContainer>
                <img src={require("../Assets/Auto/gears.gif")} height={250} width={200} alt="" />
              </MiddleImageContainer>
            </MiddleImageContainerOut>
          </MiddleContentContainerOut>

          </MiddleDesc>
          <MiddleDesc>
            <MiddleDescTitle>
            WE DO AUTOMATION
            </MiddleDescTitle>
            <Desc>
            We are experts in automation of testing machines, production machines and inherently focused on providing proficient solutions. We strive to stay current with technological trends, social issues and to maintain a creative and positive outlook on our work.
            </Desc>
          </MiddleDesc>
          <MiddleDesc>
            <MiddleDescTitle>
            OUR CLIENTS
            </MiddleDescTitle>
          </MiddleDesc>
        </Middle>
      </Wrapper>
      <OutContainerSlider>
        <AliceCarousel
          items={items}
          responsive={responsive}
          // autoHeight
          autoPlay
          // autoPlayControls
          autoPlayStrategy="none"
          autoPlayInterval={2000}
          animationDuration={2000}
          animationType="slide"
          infinite
          touchTracking={false}
          // disableDotsControls
          disableButtonsControls
        />
      </OutContainerSlider>
      <Footer />
    </Container>
  );
};

export default AutoSoln;

import React from "react";
import styled from "styled-components";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import RoomIcon from "@mui/icons-material/Room";
import PhoneIcon from "@mui/icons-material/Phone";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import { mobile, tabletPortrait } from "../responsive";
import { Link } from "react-router-dom";

const Container = styled.div`
  display: flex;
  background-color: #c9eeff;
  ${mobile({ flexDirection: "column" })}
  ${tabletPortrait({ flexDirection: "column" })}
`;
const Left = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
`;
const Logo = styled.div`
  margin-bottom: 25px;
  font-size: 30px;
`;
const SocialContainer = styled.div`
  display: flex;
`;
const SocialIcon = styled.div`
  width: 40px;
  height: 40px;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  background-color: #${(props) => props.color};
`;
const Center = styled.div`
  flex: 1;
  padding: 20px;
  ${mobile({ display: "none" })};
`;
const Title = styled.h3`
  margin-top: 0;
  margin-bottom: 30px;
`;
const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
`;
const ListItem = styled.li`
  width: 50%;
  margin-bottom: 10px;
`;
const ContactItem = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
`;
const Payment = styled.img``;
const Right = styled.div`
  padding: 20px;
  flex: 1;
  ${mobile({ backgroundColor: "#fff5f5" })}
`;
const Footer = () => {
  return (
    <Container>
      <Left>
        <Logo>
          <Link to="/" style={{ textDecoration: "none", color: "black" }}>
            <img
              src={require("../Assets/Logo/Impact.png")}
              height={80}
              alt=""
            />
          </Link>
        </Logo>
        <SocialContainer>
          <SocialIcon color="3B5999">
            <a style={{ color: 'white' }}
              href="https://www.facebook.com/impacttech1?mibextid=LQQJ4d"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FacebookIcon />
            </a>
          </SocialIcon>
          {/* <SocialIcon color="E4405F">
            <a style={{ color: 'white' }}
              href="https://instagram.com/impact358?igshid=OGQ5ZDc2ODk2ZA=="
              target="_blank"
              rel="noopener noreferrer"
            >
            <InstagramIcon />
            </a>
          </SocialIcon> */}
          <SocialIcon color="55ACEE">
            <a style={{ color: 'white' }}
              href="https://twitter.com/impact358"
              target="_blank"
              rel="noopener noreferrer"
            >
              <TwitterIcon />
            </a>
          </SocialIcon>
        </SocialContainer>
      </Left>
      <Center>
        <Title>Useful Links</Title>
        <List>
          <ListItem>
            <Link to={"/"} style={{ textDecoration: "none", color: "black" }}>
              Home
            </Link>
          </ListItem>
          <ListItem>
            <Link
              to={"/cart"}
              style={{ textDecoration: "none", color: "black" }}
            >
              Cart
            </Link>
          </ListItem>
          <ListItem>
            <Link
              to={"/about"}
              style={{ textDecoration: "none", color: "black" }}
            >
              About Us
            </Link>
          </ListItem>
          <ListItem>
            <Link
              to={"/products"}
              style={{ textDecoration: "none", color: "black" }}
            >
              Products
            </Link>
          </ListItem>
          <ListItem>
            <Link
              to={"/training"}
              style={{ textDecoration: "none", color: "black" }}
            >
              Projects & Training
            </Link>
          </ListItem>
          <ListItem>
            <Link
              to={"/automation"}
              style={{ textDecoration: "none", color: "black" }}
            >
              Automation & Solution
            </Link>
          </ListItem>
          <ListItem>My Account</ListItem>
          <ListItem>
            <Link
              to={"/terms-conditions"}
              style={{ textDecoration: "none", color: "black" }}
            >
              Terms and Conditions
            </Link>
          </ListItem>
          <ListItem>
            <Link
              to={"/wishlist"}
              style={{ textDecoration: "none", color: "black" }}
            >
              Wishlist
            </Link>
          </ListItem>
          <ListItem>
            <Link
              to={"/privacyPolicy"}
              style={{ textDecoration: "none", color: "black" }}
            >
              Privacy
            </Link>
            {" "}/{" "}
            <Link
              to={"/refundPolicy"}
              style={{ textDecoration: "none", color: "black" }}
            >
              Refund {" "}
            </Link>
            Policy
          </ListItem>
        </List>
      </Center>
      <Right>
        <Title>Contact</Title>
        <ContactItem>
          <RoomIcon style={{ marginRight: "10px" }} />
          A-14, T-Point, Pravesh Vihar, K-Block Shastri Nagar, Meerut, UP - 250004
        </ContactItem>
        <ContactItem>
          <PhoneIcon style={{ marginRight: "10px" }} />
          (+91) 9557240576, 6396886492
        </ContactItem>
        <ContactItem>
          <MailOutlinedIcon style={{ marginRight: "10px" }} />
          impact358@gmail.com
        </ContactItem>
        <ContactItem>
          <MailOutlinedIcon style={{ marginRight: "10px" }} />
          support@impactinnovate.com
        </ContactItem>
        <Payment src="" />
      </Right>
    </Container>
  );
};

export default Footer;

import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import Announcements from "../components/Announcements";
import styled from "styled-components";
import { mobile } from "../responsive";
import Footer from "../components/Footer";

const Container = styled.div``;

const Title = styled.h1`
  margin: 20px;
  ${mobile({ fontSize: "24px" })}
`;

const Wrapper = styled.div`
  margin: 20px;
  padding: 0px 20px;
`;

const Desc = styled.p`
  ${mobile({ fontSize: "14px" })}
`;

const OrderedListItem = styled.li`
  margin-top: 10px;
  ${mobile({ fontSize: "14px" })}
`;

const BoldText = styled.p`
  font-weight: 600;
  text-decoration: underline;
  ${mobile({ fontSize: "14px" })}
`;

const ContactInfo = styled.p`
  font-weight: 500;
  margin: 0px;
  ${mobile({ fontSize: "14px" })}
`;

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container>
      <Announcements />
      <Navbar />
      <Title>Privacy Policy</Title>
      <Wrapper>
        <BoldText>Effective Date: 10-05-2023</BoldText>
        <Desc>
          At Impact Technologies, we respect your privacy and are committed to
          protecting your personal information. This Privacy Policy outlines how
          we collect, use, disclose, and safeguard your information when you
          visit or make a purchase from our website. By using our website, you
          consent to the data practices described in this policy.
        </Desc>
        <ol>
          <OrderedListItem>
            <BoldText>Information We Collect</BoldText>
            <Desc>
              We may collect certain personal information from you when you
              visit our website, register an account, place an order, or
              interact with our services. The types of information we may
              collect include:
            </Desc>
            <ul>
              <li>
                <b>Personal Information:</b> Your name, email address, phone
                number, postal address, and other contact details.
              </li>
              <li>
                <b>Payment Information:</b> Payment card details, billing
                address, and other payment-related information.
              </li>
              <li>
                <b>Order Information:</b> Information about the products or
                services you purchase, order history, and shipping details.
              </li>
              <li>
                <b>Account Information:</b> Username, password, and account
                preferences.
              </li>
              <li>
                <b>Communications:</b> Correspondence and communications you
                send to us, including emails, live chat messages, and customer
                support inquiries.
              </li>
            </ul>
          </OrderedListItem>
          <OrderedListItem>
            <BoldText>How We Use Your Information</BoldText>
            <Desc>
              We use the information we collect for various purposes, including:
            </Desc>
            <ul>
              <li>
                Providing and improving our services, including order
                processing, delivery, and customer support.
              </li>
              <li>
                Personalizing your experience and tailoring content,
                recommendations, and offers to your interests.
              </li>
              <li>
                Sending you promotional and marketing communications, if you
                have opted in to receive them.
              </li>
              <li>
                Conducting research, analytics, and tracking usage patterns to
                enhance our website and services.
              </li>
              <li>
                Preventing fraud, unauthorized access, and ensuring the security
                of our website.
              </li>
            </ul>
          </OrderedListItem>
          <OrderedListItem>
            <BoldText>Information Sharing and Disclosure</BoldText>
            <Desc>
              We may share your personal information with third parties in the
              following circumstances:
            </Desc>
            <ul>
              <li>
                <b>Service Providers:</b> We may share your information with
                trusted third-party service providers who assist us in operating
                our website, processing payments, delivering products, and
                providing related services.
              </li>
              <li>
                <b>Legal Compliance:</b> We may disclose your information to
                comply with applicable laws, regulations, legal processes, or
                government requests.
              </li>
              <li>
                <b>Business Transfers:</b> In the event of a merger,
                acquisition, or sale of our company or assets, your personal
                information may be transferred as part of the transaction.
              </li>
              <li>
                <b>Consent:</b> We may share your information with your consent
                or as otherwise disclosed at the time of data collection.
              </li>
            </ul>
          </OrderedListItem>
          <OrderedListItem>
            <BoldText>Data Security</BoldText>
            <Desc>
              We implement appropriate security measures to protect your
              personal information from unauthorized access, alteration,
              disclosure, or destruction. However, please note that no method of
              transmission or storage is completely secure, and we cannot
              guarantee absolute security.
            </Desc>
          </OrderedListItem>
          <OrderedListItem>
            <BoldText>Your Rights and Choices</BoldText>
            <Desc>
              You have certain rights regarding your personal information,
              including:
            </Desc>
            <ul>
              <li>
                <b>Access and Update:</b> You can access and update your
                personal information by contacting us directly.
              </li>
              <li>
                <b>Marketing Communications:</b> You can opt-out of receiving
                marketing communications from us by following the unsubscribe
                instructions provided in the communication or contacting us.
              </li>
              <li>
                <b>Cookies:</b> You can set your browser to refuse all or some
                browser cookies or to alert you when websites set or access
                cookies. However, disabling cookies may impact your user
                experience.
              </li>
            </ul>
          </OrderedListItem>
          <OrderedListItem>
            <BoldText>Third-Party Links</BoldText>
            <Desc>
              Our website may contain links to third-party websites or services.
              We are not responsible for the privacy practices or the content of
              such websites. We encourage you to review the privacy policies of
              those third parties before providing any personal information.
            </Desc>
          </OrderedListItem>
          <OrderedListItem>
            <BoldText>Children's Privacy</BoldText>
            <Desc>
              Our website is not intended for individuals under the age of 10.
              We do not knowingly collect personal information from children. If
              you believe we have collected information from a child, please
              contact us, and we will promptly delete it.
            </Desc>
          </OrderedListItem>
          <OrderedListItem>
            <BoldText>Changes to this Privacy Policy</BoldText>
            <Desc>
              We reserve the right to update or modify this Privacy Policy at
              any time without prior notice. Any changes will be effective
              immediately upon posting the revised policy on our website. We
              encourage you to review this page periodically to stay informed
              about how we are protecting your information.
            </Desc>
          </OrderedListItem>
          <OrderedListItem>
            <BoldText>Contact Us</BoldText>
            <Desc>
              If you have any questions, concerns, or requests regarding this
              Privacy Policy or our data practices, please contact us using the
              information below:
            </Desc>
            <ContactInfo>IMPACT TECHNOLOGIES</ContactInfo>
            <ContactInfo>A-253, SHASTRI NAGAR,</ContactInfo>
            <ContactInfo>MEERUT, UTTAR PRADESH - 250002</ContactInfo>
            <ContactInfo>+91-9557240576, +91-6396886492</ContactInfo>
            <ContactInfo>impact358@gmail.com</ContactInfo>
          </OrderedListItem>
        </ol>
        <b>
          By using our website, you acknowledge that you have read, understood,
          and agree to be bound by this Privacy Policy.
        </b>
      </Wrapper>
      <Footer />
    </Container>
  );
};

export default PrivacyPolicy;

import { userRequest } from "../../RequestMethods";
import { getAllOrdersFailed, getAllOrdersStarted, getAllOrdersSuccess } from "../orderRedux";

export const getMyOrdersData = async (dispatch, item) => {
    dispatch(getAllOrdersStarted());
    await userRequest
      .get("/orders/getOrdersByUser")
      .then((resp) => {
        dispatch(getAllOrdersSuccess(resp.data));
      })
      .catch((err) => {
        console.error(err)
        dispatch(getAllOrdersFailed());
      });
  };
  
import { css } from "styled-components";

export const mobile = (props) => {
    return css`
    @media only screen and (min-width: 300px) and (max-width: 599px) {
        ${props}
    }
    `;
}
export const tabletPortrait = (props) => {
    return css`
    @media only screen and (min-width: 600px) and (max-width: 799px) {
        ${props}
    }
    `;
}
export const tabletLandscape = (props) => {
    return css`
    @media only screen and (min-width: 800px) and (max-width: 1024px) {
        ${props}
    }
    `;
}
import { createSlice } from "@reduxjs/toolkit";

const WishlistSlice = createSlice({
  name: "wishlist",
  initialState: {
    quantity: 0,
    products: [],
    isLoading: false
  },
  reducers: {
    wishlistActionStart: (state, action) => {
      state.isLoading = true;
    },
    wishlistActionFailure: (state, action) => {
      state.isLoading = false;
    },
    wishlistActionAddSuccess: (state, action) => {
      state.products = action.payload;
      state.quantity = state.products.length;
      state.isLoading = false;
    },
    addToWishList: (state, action) => {
      state.products.push(action.payload);
      state.quantity++;
    },
    removeFromWishList: (state, action) => {
      state.quantity--;
      state.products.splice(action.payload.index, 1);
    },
    emptyWishList: (state, action) => {
      state.quantity = 0;
      state.products = [];
    },
  },
});

export const { addToWishList, removeFromWishList, emptyWishList, wishlistActionStart, wishlistActionAddSuccess, wishlistActionFailure } =
  WishlistSlice.actions;
export default WishlistSlice.reducer;

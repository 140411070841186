import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    isLoading: false,
    products: [],
    quantity: 0,
    total: 0,
    message: ""
  },
  reducers: {
    cartActionStart: (state, action) => {
      state.isLoading = true
    },
    cartActionFailure: (state, action) => {
      state.isLoading = false
    },
    cartActionAddSuccess: (state, action) => {
      state.products = action.payload;
      let total = 0;
      action.payload.map(item => {
        return total += item.price*item.quantity;
      })
      state.quantity = state.products.length;
      state.total = total;
      state.isLoading = false;
    },
    emptyCartReducer: (state) => {
      state.products = [];
      state.quantity = 0;
      state.total = 0;
      state.isLoading = false
    },
    deleteCartItem:  (state, action) => {
      state.total -= (state.products[action.payload.index].price * state.products[action.payload.index].quantity)
      state.quantity--;
      state.products.splice(action.payload.index, 1);
      state.isLoading = false
    }
  },
});

export const { cartActionAddSuccess, updateProduct, emptyCartReducer, deleteCartItem, cartActionFailure, cartActionStart } = cartSlice.actions;
export default cartSlice.reducer;
